<!-- @format -->

<template>
  <div class="tree">
    <div class="tree-level d-flex">
      <div class="tree-level-item" v-for="(item, index) in treeLevel" :key="index" :style="{ background: item.color }">
        {{ item.name }}
      </div>
      <b-form-input v-model="member" class="d-inline-block mr-1" placeholder="Member ID" @input="changeSome" /> 
      <b-form-input v-model="memberNAME" class="d-inline-block mr-1" placeholder="Member NAME" @input="changeSome2" />
      <button @click="findNext">Find Next</button>

    </div>
    <b-button @click="zoomIn" style="z-index: 99; position: relative; margin: 10px">Zoom in</b-button>
    <b-button @click="zoomOut" style="z-index: 99; position: relative; margin: 10px">Zoom out</b-button>
    <b-button @click="centerScale" style="z-index: 99; position: relative; margin: 10px">Reset</b-button>
    <TreeChart
      :dataset="treeData"
      :config="treeConfig"
      ref="tree"
      class="tree-content"
      :link-style="'straight'"
    >
      <template v-slot:node="{ node, collapsed }">
        <div
          class="rich-media-node"
          :node-id="node.id"
          :node-name="node.real_name"
          :style="{ background: node.color || '#ffffff', border: collapsed ? '2px solid grey' : '' }"
        >
          <b-avatar
            ref="previewEl"
            :src="node.avatar"
            :text="avatarText(node.real_name)"
            size="48px"
            rounded
          />
          <p class="rich-media-name" style="">
            <span>{{ node.real_name }}</span>
            <span>{{ node.id }}</span>
            <span style="text-align: center; margin-left: -57px">{{ node.hasChild ? '+' : '' }}</span>
          </p>
        </div>
      </template>
    </TreeChart>
  </div>
</template>

<script>
import useJwt from '@/auth/jwt/useJwt'
import axiosIns from '@/libs/axios'
import { BAvatar, BButton } from 'bootstrap-vue'
import TreeChart from '@ssthouse/vue-tree-chart'
import { avatarText } from '@core/utils/filter'
import { codeSearch } from './code'

const getTransform = transform => {
  const arr = transform.split(') ')
  const obj = {}
  arr.forEach(item => {
    const o = item.split('(')
    const v = o[1]
    obj[o[0]] = v.slice(0, v.length - 1)
  })
  const translateArr = obj.translate ? obj.translate.split(' ') : []
  const left = translateArr[0] || 0
  return {
    top: translateArr[1] || 0,
    left: left === 0 ? 0 : left.slice(0, left.length - 1),
    scale: obj.scale || 1
  }
}

export default {
  components: {
    TreeChart,
    BButton,
    BAvatar,
  },
  data() {
    return {
      searchResults: [], // 存储搜索结果
      searchIndex: -1, // 当前搜索结果的索引
      member: '',
      treeData: {},
      codeSearch,
      searchword: '',
      tree: [],
      treeLevel: [],
      colorArray: ['#e6e2e3', '#f5eb56', '#b6cca5', '#86b6da', '#cb7a77', '#595c87', '#cd8d51', '#0f9289'],
      lock: false,
      scal: 1,
      treeConfig: { nodeWidth: 220, nodeHeight: 80, levelHeight: 200 }
    }
  },
  created() {
    this.shuju()
  },
  mounted() {
    this.init()
  },
  beforeDestroy() {
    document.onmousemove = null
    document.onmouseup = null
    this.treeLevel = []
  },
  methods: {
    avatarText,
    init() {
      const _this = this
      // const oDiv = this.$refs.tree.$el
      const box = document.getElementsByClassName('tree-content')[0]

      function addEvent(obj, xEvent, fn) {
        if (obj.attachEvent) {
          obj.attachEvent(`on${xEvent}`, fn)
        } else {
          obj.addEventListener(xEvent, fn, false)
        }
      }

      function onMouseWheel(e) {
        /* 当鼠标滚轮事件发生时，执行一些操作 */
        _this.lock = true
        const ev = e || window.event
        let down = true // 定义一个标志，当滚轮向下滚时，执行一些操作
        down = ev.wheelDelta ? ev.wheelDelta < 0 : ev.detail > 0
        if (down) {
          // console.log('鼠标滚轮向下放大---------')
          _this.scal = (parseFloat(_this.scal) + 0.01).toFixed(2)
          // console.log(`放大系数: ${_this.scal}`)
        } else {
          // console.log('鼠标滚轮向上缩小++++++++++')
          if (_this.scal == 0.01) {
            _this.scal = 0.01
            return
          }
          _this.scal = (parseFloat(_this.scal) - 0.01).toFixed(2)
          // console.log(`缩小系数: ${_this.scal}`)
        }
        _this.updateScale(_this.scal)

        if (ev.preventDefault) {
          /* FF 和 Chrome */
          ev.preventDefault() // 阻止默认事件
        }
        return false
      }

      let x1 = 0
      let y1 = 0

      function onMousemove(e) {
        const ev = e || window.event

        const x2 = ev.clientX
        const y2 = ev.clientY

        // 计算出鼠标的移动距离
        const x = x2 - x1
        const y = y2 - y1

        const tree = document.getElementsByClassName('vue-tree')[0]
        const leftTop = getTransform(tree.style.transform)
        const left = `${parseFloat(leftTop.left) + parseFloat(x)}px`
        const top = `${parseFloat(y) + parseFloat(leftTop.top)}px`
        _this.moveTree(left, top)

        if (ev.preventDefault) {
          /* FF 和 Chrome */
          ev.preventDefault() // 阻止默认事件
        }
      }

      function onMouseup() {
        box.removeEventListener('mousemove', onMousemove)
      }

      function onMousedown(e) {
        x1 = e.clientX || e.clientx || 0
        y1 = e.clientY || e.clienty || 0
        addEvent(box, 'mousemove', onMousemove)
        addEvent(box, 'mouseup', onMouseup)
      }

      addEvent(box, 'mousewheel', onMouseWheel)
      addEvent(box, 'DOMMouseScroll', onMouseWheel)
      addEvent(box, 'mousedown', onMousedown)
    },
    // 搜索
    changeSome(val) {
  const dom = document.querySelector(`div[node-id*='${val}']`)
  const { left, top } = dom.parentNode.style
  const newLeft = `${parseFloat(this.basePosition.left) - parseFloat(left)}px`
  const newTop = `-${parseFloat(top) + parseFloat(this.basePosition.top)}px`
  this.moveTree(newLeft, newTop)
},

changeSome2(val) {
  const nodes = document.querySelectorAll(`div[node-name*='${val}']`);
  this.searchResults = Array.from(nodes);
  this.currentIndex = -1; // 重置当前索引为初始值
},

findNext() {
  if (this.searchResults.length === 0) return;

  this.currentIndex = (this.currentIndex + 1) % this.searchResults.length; // 更新当前索引

  const dom = this.searchResults[this.currentIndex];
  const { left, top } = dom.parentNode.style;
  const newLeft = `${parseFloat(this.basePosition.left) - parseFloat(left)}px`;
  const newTop = `-${parseFloat(top) + parseFloat(this.basePosition.top)}px`;
  this.moveTree(newLeft, newTop);
},

    // 获取树的所有节点
    getTreeChildren(data) {
      if (!data || data.length === 0) return []
      data.forEach(item => {
        item.children = item.child
        const level_id = item.level.id - 1
        item.color = this.treeLevel[level_id].color
        if (item.child && item.child.length) {
          item.hasChild = true
          this.getTreeChildren(item.child)
        }
      })
      return data
    },
    async shuju() {
      axiosIns.get('/user/userLevelList').then(response => {
        this.treeLevel = response.data
        // 追加一个 16进制的颜色
        this.treeLevel.forEach((item, index) => {
          item.color = this.colorArray[index]
        })
      })
      const userInfo = await axiosIns.get('order/create-user')
      useJwt.offlineTree().then(response => {
        const children = this.getTreeChildren([...response?.data?.list])
        this.treeData = {
          ...JSON.parse(localStorage.getItem('userData')),
          ...userInfo.data,
          hasChild: true,
          children,
        }
        const level_id = this.treeData.level_id - 1
        this.treeData.color = this.treeLevel[level_id].color
        const treeContent = this.$refs.tree.$el
        const { transform } = treeContent.children[0].style
        const leftTop = getTransform(transform)
        this.basePosition = {
          top: leftTop.top,
          left: leftTop.left,
        }
        this.$forceUpdate()
      })
    },
    moveTree(left, top) {
      const tree = document.getElementsByClassName('vue-tree')[0]
      const content = document.getElementsByClassName('dom-container')[0]
      const str = `scale(${this.scal}) translate(${left}, ${top})`
      tree.style.transform = str
      content.style.transform = str
    },
    updateScale(scale) {
      const tree = document.getElementsByClassName('vue-tree')[0]
      const content = document.getElementsByClassName('dom-container')[0]
      const getTransformData = getTransform(tree.style.transform)
      this.scal = scale || getTransformData.scale
      const str = `scale(${scale}) translate(${getTransformData.left}, ${getTransformData.top})`
      tree.style.transform = str
      content.style.transform = str
    },
    // 还原
    centerScale() {
      this.$refs.tree.restoreScale()
      this.updateScale(1)
    },
    // 放大
    zoomIn() {
      // this.$refs.tree.zoomIn()
      this.updateScale(parseFloat(this.scal) + 0.17)
    },
    // 缩小
    zoomOut() {
      // this.$refs.tree.zoomOut()
      let scale = parseFloat(this.scal)
      if (scale - 0.17 < 0) {
        scale = 0.001
      } else {
        scale -= 0.17
      }
      this.updateScale(scale)
    },
  },
}
</script>
<style lang="scss" scoped>
.tree {
  background: rgb(105, 105, 105);
  overflow: hidden;
  min-height: calc(100vh - 110px);
  position: relative;

  .tree-content {
    position: absolute;
    top: 80px;
    left: 0;
    width: 100%;
    max-height: 80vh;
    height: 80vh;
    .node-slot {
      width: 220px;
    }
  }
}
.tree-level {
  margin: 10px 10px;
  div.tree-level-item {
    width: 80px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-right: 10px;
    color: #000;
    position: relative;
    border-radius: 10px;
    z-index: 99;
    div.pos {
      position: absolute;
      top: 0;
      right: 0;
      height: 10px;
      width: 20px;
    }
  }
}
.rich-media-node {
  display: flex;
  padding: 4px 4px 2px;
  border-radius: 3px;
}
.rich-media-name {
  margin: 0 0 0 10px;
  font-weight: bold;
  color:#ffffff;
  font-size: 1.5rem;
  display: flex;
  flex-direction: column;
  span {
    margin-bottom: 4px;
    color: #000000;
  }
}
</style>
